import classNames from 'classnames/bind';
import styles from '@/styles/superstack/companies/CompanyIndexes.module.scss';
const cx = classNames.bind(styles);

import SSImage from '@/src/component/superstack/base/SSImage';
import { defaultLogoImgSrc } from '@/src/utils/api';
import LinkTag from '@/src/component/LinkTag';
import { CompanyGeneralLinks } from '@/data/superstack/companies/CompanyGeneralLinks';
import { useRecoilValue } from 'recoil';
import { mobileState } from '@/src/utils/hooks/useMobileSize';
import CompanyLogoDefault from '@/asset/superstack/svgs/company-logo-default.svg';
import { CompanyMatchingScoreLayer } from '@/src/component/superstack/base/MatchingScoreLayer';

const CompanyJobIndex = ({ job, hasLink = true, ...props }) => {
  const companyHomeUrl = CompanyGeneralLinks(job?.company?.id).find(
    (link) => link.name === 'company_home',
  )?.url;
  const companyName = job?.company?.name;
  return (
    <div className={cx(['content-box'])} {...props}>
      <SSImage
        src={job?.company?.logo || defaultLogoImgSrc}
        width={20}
        height={20}
        objectFit={'contain'}
        className={cx('img-thumbnail')}
        priority={true}
      />
      <div className={cx(['content-with-img'])}>
        {hasLink ? (
          <LinkTag href={companyHomeUrl} isNextPage={true} hoverable={true}>
            {companyName}
          </LinkTag>
        ) : (
          <span>{companyName}</span>
        )}
        <span>
          {'ㆍ'}
          {job?.address_sido} {job?.address_sigungu}
        </span>
      </div>
    </div>
  );
};

const CompanyLogoIndex = ({
  company,
  withScore = false,
  pcOnly = false,
  imgSize = 52,
  isSummary = false,
  ...props
}) => {
  const isMobile = useRecoilValue(mobileState);
  const logoImg = company?.logo ? (
    <SSImage src={company.logo} width={imgSize} height={imgSize} objectFit={'contain'} />
  ) : (
    <CompanyLogoDefault width={imgSize} height={imgSize} />
  );
  const isNameWithScore = withScore && !isMobile;
  const companyHomeUrl = CompanyGeneralLinks(company?.id).find(
    (link) => link.name === 'company_home',
  )?.url;
  return (
    <div
      className={cx(['company-logo-index-wrapper', isMobile && !pcOnly ? 'mobile' : 'pc'])}
      {...props}
    >
      <div
        className={cx([
          'company-logo-index',
          isSummary ? 'is-summary' : '',
          isNameWithScore ? 'name-with-score' : '',
        ])}
      >
        <div className={cx('logo')}>{logoImg}</div>
        <div className={cx('name-wrapper')}>
          <p className={cx('name')}>
            <LinkTag href={companyHomeUrl} hoverable={true}>
              {company.name}
            </LinkTag>
          </p>
          {isNameWithScore && <CompanyMatchingScoreLayer companyId={company.id} />}
        </div>
      </div>
      {withScore && !isNameWithScore && <CompanyMatchingScoreLayer companyId={company.id} />}
    </div>
  );
};

export { CompanyJobIndex, CompanyLogoIndex };
