import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault('Asia/Seoul');

export const currentDate = dayjs();
export const currentDay = dayjs().date();
export const currentMonth = dayjs().month();
export const currentYear = dayjs().year();
export const nextYear = dayjs().year() + 1;
export const previousYear = dayjs().year() - 1;

export const checkYearIsCurrent = (year) => {
  return parseInt(year) === currentYear;
};
export const checkMonthIsCurrent = (month) => {
  return parseInt(month) === currentMonth + 1;
};
export const checkDateIsPrevious = ({ year, month, day }) => {
  return (
    (year && parseInt(year) < currentYear) ||
    (year && checkYearIsCurrent(year) && month && parseInt(month) < currentMonth + 1) ||
    (year && checkYearIsCurrent(year) && month && checkMonthIsCurrent(month) && day < currentDay)
  );
};

export const getMonthDays = (month) => {
  return [4, 6, 9, 11].includes(parseInt(month)) ? 30 : parseInt(month) === 2 ? 28 : 31;
};

export const calcYearMonth = (totalMonth) => {
  const year = calcFullYear(totalMonth);
  const month = totalMonth % 12;
  return [year, month];
};

export const calcFullYear = (totalMonth) => {
  // 년차(만)
  return parseInt(totalMonth / 12);
};

export const calcYear = (totalMonth) => {
  // 년차
  return parseInt(totalMonth / 12) + (totalMonth % 12 === 0 ? 0 : 1);
};

export const getSelectYearOptions = ({ from, length }) => {
  from = from * 1;
  length = length * 1;
  if (!from) {
    from = currentYear;
  }
  if (!length) {
    length = nextYear - from + 1;
  }
  return Array.from({ length: length }, (_, index) => from + length - (index + 1));
};

export const getSelectMonthOptions = () => {
  return Array.from(
    {
      length: 12,
    },
    (_, index) => index + 1,
  );
};

export const getSelectDayOptions = (dateMonth) => {
  if (!dateMonth) {
    dateMonth = 0;
  }
  return Array.from(
    {
      length: getMonthDays(dateMonth),
    },
    (_, index) => index + 1,
  );
};

export const getDateFormat = (dateYear, dateMonth, dateDay) => {
  // Return 'YYYY-MM-DD' or null
  let res = dayjs([dateYear, dateMonth, dateDay].join('-'));
  res = res.isValid() ? res.format('YYYY-MM-DD') : null;
  return res;
};

export const getDateFormatYM = (dateYM) => {
  // Return 'YYYY-MM-01' or null
  let res = dateYM;
  if (res.includes('.')) {
    res = dayjs(dateYM.split('.').join('-'));
  } else if (res.includes('-')) {
    res = dayjs(dateYM.split('-'));
  } else {
    return null;
  }
  res = res.isValid() ? res.format('YYYY-MM-DD') : null;
  return res;
};

export const getDateFormatYMDDot = (datetime) => {
  const res = dayjs(datetime).tz();
  return res.isValid() ? res.format('YYYY.MM.DD') : null;
};

export const checkDateIsPreviousMonth = (dateStr) => {
  const currentDate = dayjs();
  const inputDate = dayjs(dateStr);

  return (
    inputDate.year() < currentDate.year() ||
    (inputDate.year() === currentDate.year() && inputDate.month() < currentDate.month())
  );
};
