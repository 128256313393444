import classNames from 'classnames/bind';
import styles, { colorGray50 } from '@/styles/superstack/base/Card.module.scss';
import { useState } from 'react';
import { defaultLogoImgSrc } from '@/src/utils/api';

import { LikeIcon } from '@/src/component/superstack/base/button/Like';
import { BoxStacksCutoff } from '@/src/component/superstack/base/Box';
import SSImage from '@/src/component/superstack/base/SSImage';
import useJobLike from '@/src/utils/hooks/superstack/useJobLike';
import { toggleLike } from '@/src/utils/ss_utils';
import { getDateFormatYMDDot } from '@/src/utils/datetimes';
import { Chip } from '@/src/component/superstack/base/Chip';
import LinkTag from '@/src/component/LinkTag';
import { JobLinks } from '@/data/superstack/JobLinks';
import IconArrowRight from '@/asset/superstack/svgs/icon-arrow-right.svg';
import { CompanyJobIndex } from '@/src/component/superstack/companies/CompanyIndexes';
import { JobMatchingScoreLayer } from '@/src/component/superstack/base/MatchingScoreLayer';

const CardJob = ({ mode, job = {}, isMobile, isSaved, isActive, ...props }) => {
  const { data: { data: jobLike = {} } = {}, mutate: mutateLike } = useJobLike(job.id);

  const cx = classNames.bind(styles);
  const [mode_, setMode] = useState(mode || 'default');
  const setHover = (e) => {
    e.stopPropagation();
    if (mode_ === 'default') {
      setMode('hover');
    }
  };
  const setDefault = (e) => {
    e.stopPropagation();
    if (mode_ === 'hover') {
      setMode('default');
    }
  };
  const checkDeleteLike = (e, jobId) => {
    if (props.deleteLike) {
      props.deleteLike(jobId);
      if (isMobile) {
        e.stopPropagation();
      }
    }
  };

  return (
    <article
      className={cx([`card--${mode_}`, isActive ? 'card--active' : ''])}
      onMouseOver={(e) => {
        if (isMobile) {
          return false;
        }
        e.stopPropagation();
        setHover(e);
      }}
      onMouseOut={(e) => {
        if (isMobile) {
          return false;
        }
        e.stopPropagation();
        setDefault(e);
      }}
      {...props}
    >
      <div className={cx(['header'])}>
        <div className={cx('like-wrapper')}>
          <JobMatchingScoreLayer jobId={job.id} />
          <LikeIcon
            like={jobLike.has_liked}
            handleClick={(e) => {
              e.stopPropagation();
              toggleLike(job.id, mutateLike);
              checkDeleteLike(e, job.id);
            }}
          />
        </div>
        <div className={cx(['title-wrapper'])}>
          <div className={cx(['title'])}>
            <div className={cx(['title-text'])}>{job.title}</div>
          </div>
        </div>
        <div className={cx(['subtitle'])}>
          <div className={cx('position')}>{[job.role_kor, job.level_kor].join('ㆍ')}</div>
        </div>
      </div>
      <div className={cx(['body'])}>
        <BoxStacksCutoff
          stackDetails={job.stack_details.filter((stack_detail) => {
            return stack_detail.is_required;
          })}
        />
      </div>
      <div className={cx(['footer'])}>
        <CompanyJobIndex job={job} hasLink={false} />
      </div>
      {isSaved && job.date_liked && (
        <div className={cx(['date-wrapper'])}>
          <span className={cx('date-last')}>{getDateFormatYMDDot(job.date_liked)}에 저장</span>
        </div>
      )}
    </article>
  );
};

const CardJobDivided = ({ job = {}, isSaved, deleteLike, isMobile, ...props }) => {
  const { data: { data: jobLike = {} } = {}, mutate: mutateLike } = useJobLike(job.id);
  const cx = classNames.bind(styles);

  const checkDeleteLike = (e, jobId) => {
    if (deleteLike) {
      deleteLike(jobId);
      if (isMobile) {
        e.stopPropagation();
      }
    }
  };

  return (
    <article className={cx('card-divided--default')} {...props}>
      <div className={cx(['header'])}>
        <div className={cx('like-wrapper')}>
          <JobMatchingScoreLayer jobId={job.id} />
          <LikeIcon
            like={jobLike.has_liked}
            handleClick={(e) => {
              e.stopPropagation();
              toggleLike(job.id, mutateLike);
              checkDeleteLike(e, job.id);
            }}
          />
        </div>
        <div className={cx(['title-wrapper'])}>
          <div className={cx(['title'])}>
            <LinkTag
              isNextPage={true}
              href={JobLinks(job.id).find((link) => link.name === 'job_detail').url}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <div className={cx(['title-text'])}>{job.title}</div>
              <IconArrowRight width={9} height={17} fill={colorGray50} />
            </LinkTag>
          </div>
        </div>
        <div className={cx(['subtitle'])}>
          <div className={cx('position')}>{[job.role_kor, job.level_kor].join('ㆍ')}</div>
        </div>
      </div>
      <div className={cx(['body'])}>
        <BoxStacksCutoff
          stackDetails={job.stack_details.filter((stack_detail) => {
            return stack_detail.is_required;
          })}
        />
      </div>
      <div className={cx(['footer'])}>
        <CompanyJobIndex job={job} />
      </div>
      {isSaved && job.date_liked && (
        <div className={cx(['date-wrapper'])}>
          <span className={cx('date-last')}>{getDateFormatYMDDot(job.date_liked)}에 저장</span>
        </div>
      )}
    </article>
  );
};

const CardJobLanding = ({ job, ...props }) => {
  const cx = classNames.bind(styles);
  const stackDisplayCnt = 2;
  return (
    <article className={cx('card-job-landing')} {...props}>
      <div className={cx(['logo-box'])}>
        <span className={cx('logo')}>
          <SSImage
            src={job.company?.logo || defaultLogoImgSrc}
            width={18}
            height={18}
            objectFit={'contain'}
            className={cx('img-thumbnail')}
          />
        </span>
        <div className={cx('name')}>{`${job.company?.name}`}</div>
      </div>
      <div className={cx('title')}>{job?.title}</div>
      <div className={cx('position')}>{[job?.role_kor, job?.level_kor].join('ㆍ')}</div>
      <div className={cx('stacks-wrapper')}>
        {job?.stack_details.slice(0, stackDisplayCnt).map((stack_detail) => {
          return (
            <Chip key={stack_detail.stack.name} mode={'text'} size={'md'}>
              {stack_detail.stack.name}
            </Chip>
          );
        })}
        <span className={cx('plus-count')}>
          {job?.stack_details.length > stackDisplayCnt
            ? `+ ${job?.stack_details.length - stackDisplayCnt}`
            : ''}
        </span>
      </div>
    </article>
  );
};

const CardJobRec = ({ job, ...props }) => {
  const cx = classNames.bind(styles);
  const url = JobLinks(job.id).find((link) => link.name === 'job_detail').url;
  const stackDisplayCnt = 6;
  return (
    <article className={cx('card-job-rec')} {...props}>
      <LinkTag isNextPage={true} href={url}>
        <div className={cx(['logo-box'])}>
          <span className={cx('logo')}>
            <SSImage
              src={job.company?.logo || defaultLogoImgSrc}
              width={38}
              height={38}
              objectFit={'contain'}
              className={cx('img-thumbnail')}
            />
          </span>
          <div className={cx('name')}>{`${job.company?.name}`}</div>
        </div>
        <div className={cx('title')}>{job?.title}</div>
        <div className={cx('position')}>{[job?.role_kor, job?.level_kor].join('ㆍ')}</div>
        <div className={cx('stacks-wrapper')}>
          {job?.stack_details.slice(0, stackDisplayCnt).map((stack_detail) => {
            return (
              <Chip key={stack_detail.stack.name} mode={'text'} size={'md'}>
                {stack_detail.stack.name}
              </Chip>
            );
          })}
          <span className={cx('plus-count')}>
            {job?.stack_details.length > stackDisplayCnt
              ? `+ ${job?.stack_details.length - stackDisplayCnt}`
              : ''}
          </span>
        </div>
      </LinkTag>
    </article>
  );
};

const CardCompanyJob = ({ job, ...props }) => {
  const cx = classNames.bind(styles);
  const url = JobLinks(job.id).find((link) => link.name === 'job_detail').url;
  return (
    <article className={cx('card-company-job')} {...props}>
      <LinkTag isNextPage={true} href={url}>
        <JobMatchingScoreLayer jobId={job.id} style={{ marginBottom: '16px' }} />
        <div className={cx('title')}>{job?.title}</div>
        <div className={cx('position')}>{[job?.role_kor, job?.level_kor].join('ㆍ')}</div>
        <BoxStacksCutoff
          stackDetails={job.stack_details.filter((stack_detail) => {
            return stack_detail.is_required;
          })}
          style={{ marginTop: '16px' }}
        />
      </LinkTag>
    </article>
  );
};

export { CardJob, CardJobLanding, CardJobDivided, CardJobRec, CardCompanyJob };
