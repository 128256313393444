import { useState, useEffect } from 'react';

import { Button } from '@/src/component/superstack/base/Button';

import IconBookmarkEmpty from '@/asset/superstack/svgs/icon-bookmark.svg';
import IconBookmarkFull from '@/asset/superstack/svgs/icon-bookmark-full.svg';

export const LikeButton = ({
  like = false,
  onClick,
  size,
  style,
  likeObjName = '관심 포지션',
  ...props
}) => {
  const [hasLike, setLike] = useState(like || false);
  const Bookmark = hasLike ? IconBookmarkFull : IconBookmarkEmpty;
  useEffect(() => {
    setLike(like);
  }, [like]);
  return (
    <Button
      mode={'outline'}
      size={size || 'xl'}
      style={style}
      onClick={() => {
        {
          props.likeListJobDetail ? '' : setLike((hasLike) => !hasLike);
        }
        onClick();
      }}
      title={hasLike ? `"${likeObjName}"에서 해제` : `"${likeObjName}"에 추가`}
      {...props}
    >
      <Bookmark width={24} height={22} />
    </Button>
  );
};

export const LikeIcon = ({ size = 'md', like = false, handleClick, ...props }) => {
  const [hasLike, setLike] = useState(like || false);
  const Bookmark = hasLike ? IconBookmarkFull : IconBookmarkEmpty;
  const SIZES = {
    md: [24, 22],
  };
  const [w, h] = SIZES[size];
  useEffect(() => {
    setLike(like);
  }, [like]);
  return (
    <span
      onClick={(e) => {
        setLike((hasLike) => !hasLike);
        handleClick(e);
      }}
      title={hasLike ? '"관심 포지션"에서 해제' : '"관심 포지션"에 추가'}
      {...props}
    >
      <Bookmark width={w} height={h} />
    </span>
  );
};
